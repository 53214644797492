<template>
    <div>
        <v-flex>
            <v-layout wrap justify-center>
<v-flex xs6>

</v-flex>
            </v-layout>
        </v-flex>
      <v-layout wrap justify-center pa-4 class="opensansregular">
        <v-flex xs11>
          <v-card>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-carousel height="auto" cycle :interval="2000" hide-delimiters show-arrows-on-hover>
                  <v-carousel-item v-for="(item, i) in recentBlogs" :key="i">
                    <v-card class="equal-height-card">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout wrap justify-start>
                            <v-flex   xs12 pa-3 lg3 v-for="(item2, k) in item.photos" :key="k">
                              <v-img
                              style="border-radius: 12px;"
                              contain
                              height="220px"
                        
                              :src="mediaURL + item2"></v-img>
                            </v-flex>
                            <v-flex xs12 lg8>
                              <v-layout wrap justify-center>
                                <v-flex xs11 text-left pt-1 style="font-size: 16px;color: grey;">
                                    {{ formatDate(new Date(item.create_date)) }}

                                </v-flex>
                                <v-flex xs11 text-left pt-6 style="font-size: 14px;color: black;">
                                  <span v-html="item.content"></span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
            <v-layout wrap justify-center pa-4 class="opensansregular">
        <v-flex xs7>
          <v-card>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-carousel height="auto" cycle :interval="2000" hide-delimiters show-arrows-on-hover>
                  <v-carousel-item v-for="(item, i) in trendingNews" :key="i">
                    <v-card class="equal-height-card2">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout wrap justify-start>
                            <v-flex xs12 pa-3 lg5 >
                              <v-img
                              style="border-radius: 12px;"

                              contain
                              height="220px"
                        
                              :src="mediaURL + item.photo"></v-img>
                            </v-flex>
                            <v-flex xs12 lg4>
                              <v-layout wrap justify-center>
                                <v-flex xs11 text-left pt-1 style="font-size: 16px;color: grey;">
                                    {{ formatDate(new Date(item.create_date)) }}

                                </v-flex>
                                <v-flex xs11 text-left pt-1 style="font-size: 16px;color: black;">
                                    {{ item.title }}

                                </v-flex>
                                <v-flex xs11 text-left pt-2 style="font-size: 14px;color: black;">
                                  <span v-html="item.content"></span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs4 pl-7>
            <v-card>
                <v-layout wrap justify-center>
                <v-flex xs7 pt-4>
                    <v-card elevation="0" color="#f9f9f9">
                        <v-layout pa-4 wrap  justify-center  >
                        <v-flex xs8 style="font-size: 16px;">
                            Live Auction

                        </v-flex>
                        <v-flex xs1 >
                          <v-icon color="black">mdi-access-point</v-icon>

                        </v-flex>
                    </v-layout>
                    </v-card>
                    
                </v-flex>
                <v-flex xs7 pt-4>
                    <v-card elevation="0" color="#f9f9f9">
                        <v-layout pa-4 wrap  justify-center  >
                        <v-flex xs8  style="font-size: 16px;">
                           Won Lots

                        </v-flex>
                        <v-flex xs1 >
                          <v-icon color="black">mdi-clipboard-check-outline</v-icon>

                        </v-flex>
                    </v-layout>
                    </v-card>
                    
                </v-flex><v-flex xs7 pt-4 pb-4 style="cursor: pointer;"  @click="$router.push('/addlot')">
                    <v-card elevation="0"  color="#f9f9f9">
                        <v-layout pa-4 wrap  justify-center  >
                        <v-flex xs8 style="font-size: 16px;">
                           Add Lots

                        </v-flex>
                        <v-flex xs1 >
                          <v-icon color="black">mdi-land-plots</v-icon>

                        </v-flex>
                    </v-layout>
                    </v-card>
                    
                </v-flex>
            </v-layout>
            </v-card>
            
        </v-flex>
      </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        myLots: [],
        recentBlogs: [],
        trendingNews: [],
      };
    },
    mounted() {
      this.getList();
    },
  
    methods: {
        formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
      getList() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/trader/dashboard",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.myLots = response.data.myLots;
            this.trendingNews = response.data.trendingNews;
            this.recentBlogs = response.data.recentBlogs;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  .equal-height-card {
    height: 250px; 
  }
  /* .equal-height-card2 {
    height: 260px; 
  } */

  </style>
  