<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#29807c"
        spinner="spinner" />
    <v-layout v-if="userRole === 'Customer'" wrap justify-center class="mainfont">
     
      <v-flex xs12>
        <v-layout wrap justify-center pt-4>
          <v-flex data-aos="zoom-in-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="1000" xs11 lg10>
            <v-card class="mainbg4" style="border-radius: 27px">
              <v-layout justify-start wrap>
           
                <v-flex xs8>
                  <v-img contain src="../../assets/Images/pattern1.2.png" style="border-radius: 27px"></v-img></v-flex>
                <v-flex xs12 lg6 pt-6>
                  <v-layout wrap justify-center>
                    <v-flex lg8 xs11 :class="{ 'text-left': $vuetify.breakpoint.mdAndUp }">
                      <span style="
                          font-family: opensansregular;
                          font-size: 16px;
                          color: #185a57;
                          font-weight: bold;
                        ">STORY OF 1000 PLANTERS
                      </span>
                    </v-flex>
                    <v-flex xs11 lg9 :class="{ 'pl-6': $vuetify.breakpoint.mdAndUp }" class="text-left">
                      <span style="
                          font-family: archivoregular;
                          font-size: 35px;
                          color: #000;
                          font-weight: 900;
                        ">The Spice Emporium Find the Freshest spices in One
                        Place
                      </span>
                    </v-flex>
                    <v-flex xs11 lg9 pt-1 :class="{ 'pl-4': $vuetify.breakpoint.mdAndUp }" class="pl-6  text-left">
                      <span style="
                          font-family: archivoregular;
                          font-size: 18px;
                          color: #645e5e;
                        ">Experience the Finest Selection of Cardamom and Pepper
                        Spices
                      </span>
                    </v-flex>
                    <v-flex xs9 pl-6 pt-4>
                      <v-btn style="border-radius: 7px;" height="44px" width="205px" class="no-uppercase" dark
                        color="#13736f">
                        <span style="
                                    font-family: dmsansregular;
                                    font-size: 16px;
                                    color: white;
                                    letter-spacing: 1px;
                                    cursor: pointer;
                                  ">
                          <span>Learn More</span>
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 lg4>
                  <v-img height="100%" width="100%" src="../../assets/Images/homeimage3.png"></v-img>
                </v-flex>
                <v-flex xs8 pt-2>
                  <div style="padding-top: 3px">
                    <v-img contain src="../../assets/Images/pattern2.3.png" style="border-radius: 27px"></v-img>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pl-14 pt-4 pb-4 justify-center>
          <v-flex pa-5 lg10 xs11>
            <v-layout wrap justify-center>
              <v-flex data-aos="slide-left" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500" xs6 lg3>
                <v-layout wrap justify-center>
                  <v-flex xs2 pa-1>
                    <template>
                      <v-img src="../../assets/Images/homeicon1.png"></v-img>
                    </template>
                  </v-flex>
                  <v-flex pl-6 pt-1 xs9 lg6 text-left style="
                      font-family: opensansregular;
                      color: black;
                      font-size: 16px;
                      font-weight: bold;
                    ">
                    Top Quality Products
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex data-aos="slide-left" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500" xs6 lg3>
                <v-layout wrap justify-center>
                  <v-flex xs2 pa-1>
                    <template>
                      <v-img src="../../assets/Images/homeicon2.png"></v-img>
                    </template>
                  </v-flex>
                  <v-flex pl-6 pt-1 xs9 lg6 text-left style="
                      font-family: opensansregular;
                      color: black;
                      font-size: 16px;
                      font-weight: bold;
                    ">
                    Secure
                    <br /> Payment

                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs6 lg3 data-aos="slide-left" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500">
                <v-layout wrap justify-center>
                  <v-flex xs2 pa-1>
                    <template>
                      <v-img src="../../assets/Images/homeicon3.png"></v-img>
                    </template>
                  </v-flex>
                  <v-flex pl-6 pt-1 xs9 lg6 text-left style="
                      font-family: opensansregular;
                      color: black;
                      font-size: 16px;
                      font-weight: bold;
                    ">
                    Top quality products
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs6 lg3 data-aos="slide-left" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500">
                <v-layout wrap justify-center>
                  <v-flex xs2 pa-1>
                    <template>
                      <v-img src="../../assets/Images/homeicon4.png"></v-img>
                    </template>
                  </v-flex>
                  <v-flex pl-6 pt-1 xs9 lg5 text-left style="
                      font-family: opensansregular;
                      color: black;
                      font-size: 16px;
                      font-weight: bold;
                    ">
                    Fast
                    <br /> Delivery
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>


        <v-layout wrap>
          <v-flex xs12>
            <products />
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-card elevation="2" height="70px">

              <v-layout wrap justify-center fill-height>

                <v-flex text-center xs12 align-self-center>
                  <span style="
                        font-family: dmsansregular;
                        color: black;
                        font-size: 18px;
                        font-weight:bolder;
                      ">Order Spices in Custom Weights and
                    Grades Now!</span>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;

                  <v-btn @click="dialog2 = true" class="no-uppercase" width="110px" color="#13736f"><span style="
  font-family: opensansregular;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  cursor: pointer;
">&nbsp;Order &nbsp;
                    </span>
                  </v-btn>
                </v-flex>

              </v-layout>
            </v-card>

          </v-flex>


        </v-layout>
        <v-layout wrap justify-center pt-4>
          <v-flex xs11 lg10>
            <v-card style="border-radius: 27px" class="hidden-sm-and-down bg10">
              <v-layout justify-center center fill-height>
                <v-flex data-aos="slide-right" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500" xs lg4>
                  <v-img src="../../assets/Images/banner1.png"></v-img>
                </v-flex>
                <v-flex xs11 data-aos="zoom-in-left" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500"
                  lg6 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-center style="
                        font-family: dmsansregular;
                        color: white;
                        font-size: 30px;
                      ">
                      Experience Seamless Convenience
                    </v-flex>
                    <v-flex xs9 text-center class="dmsansregular" style="color: white; font-size: 40px">
                      Download Our App Now !
                    </v-flex>
                    <v-flex xs4 pt-3>
                      <v-img contain src="../../assets/Images/playstore.png"></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card class="hidden-lg-and-up bg10" style="border-radius: 27px">
              <v-layout xs12 wrap justify-center center fill-height>
                <v-flex xs12>
                  <v-img src="../../assets/Images/banner1.png"></v-img>
                </v-flex>
                <v-flex xs12 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex pt-3 xs12 text-center style="color: white; font-size: 23px">
                      Experience Seamless Convenience
                    </v-flex>
                    <v-flex xs12 text-center style="color: white; font-size: 40px">
                      Download Our App Now !
                    </v-flex>
                    <v-flex xs12 pa-3>
                      <v-img contain src="../../assets/Images/playstore.png"></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 pt-8 data-aos="zoom-in-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="1000">
            <v-img src="../../assets/Images/homeimage5.png">
              <v-layout wrap justify-center align-center fill-height>
                <v-flex xs12 lg7 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex data-aos="slide-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="1000" xs10
                      text-center style="
                        font-family: opensansregular;
                        color: white;
                        font-size: 40px;
                        font-weight: bold;
                      ">Discover a World of Flavors
                    
                 
                    </v-flex>
                    <v-flex data-aos="slide-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="1000" pt-5
                      xs12 text-center style="
                        font-family: opensansregular;
                        color: white;
                        font-size: 25px;
                      ">Unleash Culinary Excellence with Our Premium Spices and
                      Herbs. Shop Now for Exceptional Taste and Aroma!</v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog :retain-focus="true" persistent v-model="dialog2" max-width="650px">
        <v-card class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
          <v-layout wrap justify-center>
            <v-flex>
              <v-layout pa-4 wrap justify-center>
                <v-flex xs12 text-right>
                  <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                    mdi-close
                  </v-icon>
                </v-flex>

                <v-flex xs5 pt-4 pr-5>
                  <span style="color: black; font-size: 16px; font-weight: bold">Name</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="name"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs5 pl-5 pt-4>
                  <span style="color: black; font-size: 16px; font-weight: bold">Email</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense v-model="email" hide-details
                      :rules="emailRules"></v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs5 pt-3 pr-5>
                  <span style="color: black; font-size: 16px; font-weight: bold">Mobile Number</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="phone"
                      hide-details :rules="phoneRules"></v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs5 pl-5 pt-3>
                  <span style="color: black; font-size: 16px; font-weight: bold">Pincode</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="number" v-model="pincode"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs5 pt-3 pr-5>
                  <span style="color: black; font-size: 16px; font-weight: bold">City</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="city"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs5 pl-5 pt-3>
                  <span style="color: black; font-size: 16px; font-weight: bold">State</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="state"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs6 pr-2 pt-3>
                  <span style="color: black; font-size: 16px; font-weight: bold">Landmark</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="text" v-model="landmark"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs3 pt-3 pr-2>
                  <span style="color: black; font-size: 16px; font-weight: bold">Weight</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" dense type="number" v-model="weight"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs1 pt-3 class="mainfont">
                  <span style="color: black; font-size: 16px; font-weight: bold">Unit</span>
                  <v-autocomplete outlined dense color="black" :items="itemArray2" v-model="weightType" item-text="name"
                    item-value="_id" item-color="#FF1313" hide-details clearable class="custom-autocomplete mainfont">
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs10 pt-3 class="mainfont">
                  <span style="color: black; font-size: 16px; font-weight: bold">Category</span>
                  <v-autocomplete outlined dense color="black" :items="category" v-model="categoryId" item-text="name"
                    item-value="_id" item-color="#FF1313" hide-details clearable class="custom-autocomplete mainfont">
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs10 pt-3>
                  <span style="color: black; font-size: 16px; font-weight: bold">Address</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field color="#13736f" outlined background-color="white" type="text" v-model="address"
                      hide-details>
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs10 text-right pt-4>
                  <v-btn @click="addOrder()" dark width="100px" color="#13736f">
                    <span style="color: white; font-size: 14px">Add</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout wrap v-else-if="userRole === 'Trader'">
      <v-flex text-center>
        <trader />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import trader from "./TraderDashBoard.vue";

import { mdimagnify } from "@mdi/js";
import products from "./ProductsPage.vue";

export default {
  components: {
    products,
    trader,
  },
  data() {
    return {
      userProfileTabs: null,
      subtab: null,
      price: null,
      category: null,
      description: null,
      productImage: null,
      itemArray2: ["kg", "g"],
      weight: null,
      name: null,
      email: null,
      phone: null,
      address: null,
      city: null,
      pincode: null,
      state: null,
      landmark: null,
      productType: null,
      productCode: null,
      liveAuctions: [],
      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,
      // dialog2: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Valid email is required",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]+$/.test(v) || "Only digits are allowed",
        (v) => (v && v.length === 10) || "Phone number must be 10 digits",
      ],
      preview: null,
      tab: null,
      items: ["Dashboard", "Auctions"],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      categoryId: null,
      password: null,
    
      categoryarray: [],
      formData: new FormData(),
      id: this.$route.query.id,
      productname: null,
      productarray: [],
      list: {},
      currentpage: 1,
      limit: 10,
      weightType: null,
      msg: null,
      showSnackBar: false,
      curid: [],
      dialog2: false,

    };
  },
  computed: {
    userRole() {
    const role = localStorage.getItem("userRole");

    return role || "defaultRole";
  },
    chevronDownIcon() {
      return mdimagnify;
    },
  },
  mounted() {
    this.getProfile();
    this.getCategory();


  },
  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addOrder() {
      axios({
        method: "POST",
        url: "/custom/order/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          weightType: this.weightType,
          email: this.email,
          categoryId: this.categoryId,
          phone: this.phone,
          address: this.address,
          weight: this.weight,
          pincode: this.pincode,
          city: this.city,
          state: this.state,
          landmark: this.landmark,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.liveAuctions = this.list.liveAuctions;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.bg10 {
  background: var(--grend,
      linear-gradient(252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%));
}
</style>