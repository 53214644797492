<template>
  <div>
    <v-layout wrap pl-lg-10 justify-center>
      <v-flex xs12>
        <v-layout  pa-3 wrap justify-center>
          <v-flex xs11 pr-6 pt-4  text-center style="
              font-family: opensansregular;
              color: black;
              font-size: 30px;
              font-weight: bold;
            ">Shop by spice Type</v-flex>

          <v-flex xs11 style="cursor: pointer;">
            <v-layout wrap :class="{
              '': $vuetify.breakpoint.mdAndUp,
              '': $vuetify.breakpoint.mdAndUp,
            }" justify-center>
              <v-flex pl-6 data-aos="zoom-in-down" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="200" xs12 sm6
                lg4 xl3  @click="getListByCategory(item._id)" v-for="(item, i) in category" :key="i" class="image-container">
                <v-img
               
                height="215px" width="350px" style="border-radius: 20px" :src="mediaURLnew + item.icon">
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs12 text-center align-self-center style="
                        font-family: opensansregular;
                        color: white;
                        font-size: 30px;
                        font-weight: bold;
                      ">{{ item.name }}</v-flex>
                  </v-layout>
                </v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end>
          <v-flex xs4 lg5>
            <v-layout wrap justify-center>
              <v-flex xs12 lg9 pl-4 text-left>
                <span style="color: black; font-size: 20px; font-weight: bold">Products</span>

              </v-flex>
            </v-layout>


          </v-flex>

          <v-flex xs8 lg7>
            <v-layout wrap justify-center>
              <v-flex xs9 pr-3>
                <v-layout justify-end   wrap>
                  <v-flex lg4 xs6 pr-1>
                    <v-text-field 
                    v-model="searchKeyword"
                     solo 
                     flat
                     outlined
                     dense
                      hide-details
                       clearable color="black" label="Search "
                      class="custom-text-field rounded-lg">
                      <template v-slot:append>
                        <v-icon color="black">mdi-magnify</v-icon>
                      </template>
                      <template v-slot:label>
                        <span class="custom-label-text"
                          style="font-family:opensansregular;color: black; font-size: 14px">Search </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  
                </v-layout>
              </v-flex>

            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2 pl-xs-0 pl-lg-8 >
          <v-flex xs11>
            <v-layout wrap justify-center>
              <v-flex data-aos="zoom-in-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500"
                class="hover-effect" xs8 sm5 lg3 pt-6 v-for="(item, i) in list" :key="i">
                <v-card elevation="0" outlined width="270px">
                  <v-layout wrap justify-center pa-3>
                    <center>
                      <v-flex
                                                    class="hidden-sm-and-down"
                                                    pl-8 xs4 style="cursor: pointer;"
                                                    @click="$router.push('/products?id=' + item._id)">
                                                        <v-avatar size="90px">
                                                            <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                                                        </v-avatar>
                                                    </v-flex>
                        </center>
                        <v-flex
                                                class="hidden-md-and-up"
                                                    pl-7 xs4 style="cursor: pointer;"
                                                    @click="$router.push('/products?id=' + item._id)">
                                                        <v-avatar size="90px">
                                                            <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                                                        </v-avatar>
                                                    </v-flex>
                        <v-flex
      v-if="!item.inWishlist"
      xs5
      text-right
      @click="addtoWishlist(item._id)" 
      style="
        cursor: pointer;
      "

    >
      <v-icon color="black" size="200%">mdi-bookmark-outline</v-icon>
    </v-flex>
    <v-flex  style="
        
        cursor: pointer;
      "
 v-else xs5 text-right @click="addtoWishlist(item._id)"> 
      <v-icon color="#E96125" size="200%">mdi-bookmark</v-icon>
    </v-flex>

                    <v-flex @click="$router.push('/products?id=' + item._id)" pt-3 text-center xs12
                      style="color: #BCB4B4;cursor: pointer; font-size: 12px;" class="opensansregular">
                      {{ item.categoryId.name }}
                    </v-flex>

                    <v-flex pt-1 text-center xs12 class="dmsansregular"
                      style="color: black; font-size: 20px;cursor: pointer; font-weight: 500">
                      {{ item.name }}
                    </v-flex>

                    <v-flex @click="$router.push('/products?id=' + item._id)" pt-1 text-center xs12 class="dmsansregular"
                      style="color: black;cursor: pointer; font-size: 14px; font-weight: 600">
                      {{ item.weight }} {{ item.weightType }}
                    </v-flex>
                    <v-flex pt-1 text-center xs12 class="dmsansregular"
                      style="color: #29807c; font-size: 20px; font-weight: 700">
                      Rs {{ item.price }}
                    </v-flex>
                    <v-flex v-if="!item.inCart" pt-1 text-center xs12>
                      <v-btn v-if="item.stock>0" @click="addtoCart(item._id)" class="no-uppercase" width="110px" color="#13736f"><span style="
                            font-family: opensansregular;
                            font-size: 14px;
                            color: #FFFFFF;
                            letter-spacing: 1px;
                            cursor: pointer;
                          ">&nbsp;Add to Cart  &nbsp;
                        </span>
                      </v-btn>
                      <v-btn v-else disabled class="no-uppercase" width="110px" color="#13736f"><span style="
                            font-family: opensansregular;
                            font-size: 14px;
                            color: red;
                            letter-spacing: 1px;
                            cursor: pointer;
                          ">&nbsp;Out of Stock  &nbsp;
                        </span>
                      </v-btn>
                    </v-flex>

                    <v-flex v-else pt-1 text-center xs12>
                      <v-btn v-if="item.stock>0" @click="$router.push('/cart')" class="no-uppercase" width="110px" color="#13736f"><span
                          style="
                            font-family: opensansregular;
                            font-size: 14px;
                            color: #FFFFFF;
                            letter-spacing: 1px;
                            cursor: pointer;
                          ">&nbsp;Go to Cart &nbsp;
                        </span>
                      </v-btn>
                      <v-btn v-else disabled class="no-uppercase" width="110px" color="#13736f"><span style="
                            font-family: opensansregular;
                            font-size: 14px;
                            color: red;
                            letter-spacing: 1px;
                            cursor: pointer;
                          ">&nbsp;Out of Stock  &nbsp;
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout pt-9 pb-6 wrap justify-center>
          <v-flex align-self-center>
            <div>
              <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                v-model="currentPage" color="#13736f"></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <div v-if="cartAddSuccess" class="success-message">
      Product added to cart successfully!
    </div>

  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,

      cartAddSuccess:false,
      pages: 0,
      preview: null,
      link: null,
      prev: null,
      page: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      icon: null,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      editdailog: false,
      curid: [],
      searchKeyword: null,
      categoryId:{},
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      category:[],
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
    this.getCategory();

  },
  watch: {
    currentPage() {
      this.getList();
    },
    searchKeyword() {
      this.getList();
    },
    status() {
      this.getList();
    },
  },
  methods: {
    getListByCategory(categoryId) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/store/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          categoryId: categoryId, 
          page: this.currentPage,
          searchKeyword: this.searchKeyword,
          limit: 12,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

   addtoCart(productId) {
  this.appLoading = true;
  this.cartAddSuccess = false; 

  axios({
    method: "POST",
    url: "/cart/add",
    headers: {
      token: localStorage.getItem("token"),
    },
    data: {
      productId: productId,
      quantity: this.quantity,
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data === true) {
        this.showSnackBar("Product added to cart successfully!"); 
      }
      location.reload();
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},


addtoWishlist(productId) { 
      axios({
        method: "POST",
        url: "/wishlist/add/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productId: productId, 
          wishlistType: "Product",
        },
      })
        .then((response) => {
          location.reload();
          if (response.data.status === true) {
            // Wishlist updated successfully, handle any UI changes
          } else {
            console.log("Adding to wishlist failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.categoryId = this.category.map(item => item._id);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/store/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          searchKeyword: this.searchKeyword,
          limit: 12,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.hover-effect {
  transition: transform 0.3s ease-in-out;
}

.hover-effect:hover {
  transform: scale(1.05);
}
.grains-effect {
  position: relative;
  background-color: #f2f2f2;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.image-container {
  padding: 35px;
}

.image-container {
  transition: transform 0.3s ease-in-out;
}

.image-container:hover {
  transform: scale(1.05);
}

.animated-card {
  perspective: 1000px;
  transition: transform 0.3s ease-in-out;
}

.animated-card:hover {
  transform: rotateY(10deg);
}

.card-content {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.v-card:hover .card-content {
  opacity: 1;
}

.bg1 {
  background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
}

.bg2 {
  background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
}

.bg3 {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}

.bg4 {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}

.bg5 {
  background-image: linear-gradient(to right, #fe7b00 0%, #e9e390 100%);
}

.bg6 {
  background-image: linear-gradient(to right, #f093fb 0%, #b445e7 100%);
}

.bg7 {
  background-image: linear-gradient(to right, #13736f 0%, #13736f 100%);
}

.v-carousel__controls .v-carousel__controls__item {
  width: 3px;
  height: 3px;
}

.bg10 {
  background: var(--grend,
      linear-gradient(252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%));
}

.cardbg {
  background-image: linear-gradient(269.6deg, #29807c -31.66%, #29807c);
}

.grains-effect {
  position: relative;
  background-color: #f2f2f2;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.image-container {
  padding: 35px;
  /* Adjust the padding value as needed */
}

.image-container {
  transition: transform 0.3s ease-in-out;
}

.image-container:hover {
  transform: scale(1.05);
}

.animated-card {
  perspective: 1000px;
  transition: transform 0.3s ease-in-out;
}

.animated-card:hover {
  transform: rotateY(10deg);
}

.card-content {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.v-card:hover .card-content {
  opacity: 1;
}
</style>
